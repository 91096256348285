<template>
  <div class="service-filters">
    <h2 class="white--text">FILTERS</h2>
    <HelpFormInputVue
      dark
      v-model="pSearch"
      :timeout="300"
      label="Search"
      :helpText="'Provide any text to search across services'"
      :tooltipAttrs="{
        left: true,
      }"
    ></HelpFormInputVue>
  </div>
</template>


<script>
import HelpFormInputVue from "../../../atoms/common/inputs/HelpFormInput.vue";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      pSearch: this.value.search ? this.value.search : undefined,
      pType: this.value.type ? this.value.type : undefined,
    };
  },
  components: {
    HelpFormInputVue,
  },

  watch: {
    value: {
      handler(newVal) {
        this.filters = newVal;
      },
      deep: true,
    },
    filters: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },

    search(newVal) {
      this.pSearch = newVal;
    },

    pSearch(newVal) {
      this.$emit("input", {
        search: newVal,
      });
    },
    pType(newVal) {
      this.$emit("input", {
        type: newVal,
      });
    },
  },

  methods: {},
};
</script>


<style lang="scss" scoped>
.service-filters {
  // margin-right: 30px;
  padding: 40px;
  width: 100%;
}
</style>