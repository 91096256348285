<template>
  <v-card
    :loading="loading"
    class="analyzeCategory-item bg-grey-gradient"
    v-bind="$attrs"
    @click="() => $emit('click', value)"
    :ripple="tab === 0 ? true : false"
    @mouseover="() => $emit('mouseover', value)"
    @mouseleave="() => $emit('mouseleave', value)"
  >
    <div :class="{ active }" class="item-active-indicator"></div>

    <div class="analyzeCategory-type-img control"></div>

    <div
      class="analyzeCategory-status"
      :class="{
        success: value.type === 'UPWORK',
      }"
    >
      <span v-if="value.type === 'UPWORK'">UPWORK</span>
      <span v-else-if="value.type === 'CONTACT_US'">CONTACT US</span>
      <span v-else>{{ value.type }}</span>
    </div>

    <v-card-title class="analyzeCategory-title">
      {{ value.name }}
    </v-card-title>
    <v-divider class="mt-2 mr-4 ml-4"></v-divider>
    <div>
      <v-card-text class="mt-0 pt-0">
        <v-row class="justify-space-between">
          <v-col cols="12">
            <v-row align="center" class="mx-0"> </v-row>

            <div class="my-4 text-subtitle-1"></div>

            <div class="">
              <!-- <h3>{{ value.short_description }}</h3> -->

              <v-row>
                <v-col>
                  <h4 class="brief">
                    SCHEDULE: {{ value.execution_schedule }}
                  </h4>
                  <a :href="value.link" target="blank"> Open Link to search</a>
                </v-col>
                <v-col>
                  Good Threshold : {{ params.badThreshold }} <br />
                  Bad Threshold :
                  {{ params.goodThreshold }}
                </v-col>
              </v-row>
              <!-- <h4 class="brief">INITIAL INFO:</h4>
                  <p class="simple-text analyzeCategory-description">
                    {{ cutFormatter(value.initial_info, 150) }}
                  </p> -->
            </div>
          </v-col>
          <!-- <v-col cols="4"> </v-col> -->
        </v-row>
      </v-card-text>
    </div>

    <div class="card-actions-bottom">
      <v-divider class="mx-4"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="secondary"
          text
          @click.stop.prevent="() => $emit('click', value)"
        >
          Open Editor
        </v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import { FormatterHelper } from "../../../../helpers/formatter.helper";

export default {
  props: {
    value: {},
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      durationFormatter: FormatterHelper.timeDuration,
      cutFormatter: FormatterHelper.cutString,

      loading: false,
      show: false,
      tab: 0,
      badwords: [],
      newKeyword: "",
      showAnimation: false,
    };
  },
  computed: {
    params: function () {
      let output = {};

      try {
        output = JSON.parse(this.value.config);
      } catch (error) {
        console.log("err", error);
      }
      return output;
    },
  },
  components: {},

  methods: {
    onOpen() {
      this.$emit("onOpen", this.value);
    },
    onEdit() {
      this.$emit("onEdit", this.value);
    },
  },
};
</script>


<style lang="scss" scoped>
.analyzeCategory-item {
  z-index: 2;
  overflow: hidden;

  .title-link {
    text-decoration: none;
    color: white;
    transition: color 0.12s ease-in-out;

    &:hover {
      color: brown;
    }
  }
  .analyzeCategory-type-img {
    position: absolute;
    height: 100%;
    z-index: -1;
    left: 0;
    top: 0;
    width: 30%;
    -webkit-mask-image: -webkit-gradient(
      linear,
      left top,
      left right,
      from(rgba(0, 0, 0, 1)),
      to(rgba(0, 0, 0, 0))
    );
    mask-image: linear-gradient(to right, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  .analyzeCategory-title {
    text-transform: uppercase;
    display: inline-block;
    padding-right: 60px;
  }

  .brief {
    color: #cfd8dc !important;
  }

  .analyzeCategory-status {
    transform: rotate(45deg);
    background-color: red;
    color: white;
    position: absolute;
    right: -40px;
    width: 150px;
    top: 0;
    text-transform: uppercase;
    /* overflow: hidden; */
    /* margin: 0 auto; */
    text-align: center;
    top: 22px;
  }

  .badwords {
    max-height: 100px;
    overflow: auto;
  }

  .success-checkmark {
    width: 80px;
    height: 115px;
    margin: 0 auto;
    margin-top: 64px;

    .check-icon {
      width: 80px;
      height: 80px;
      position: relative;
      border-radius: 50%;
      box-sizing: content-box;
      border: 4px solid #4daf50;

      &::before {
        top: 3px;
        left: -2px;
        width: 30px;
        transform-origin: 100% 50%;
        border-radius: 100px 0 0 100px;
      }

      &::after {
        top: 0;
        left: 30px;
        width: 60px;
        transform-origin: 0 50%;
        border-radius: 0 100px 100px 0;
        animation: rotate-circle 4.25s ease-in;
      }

      &::before,
      &::after {
        content: "";
        height: 100px;
        position: absolute;
        background: transparent;
        transform: rotate(-45deg);
      }

      .icon-line {
        height: 5px;
        background-color: #4daf50;
        display: block;
        border-radius: 2px;
        position: absolute;
        z-index: 10;

        &.line-tip {
          top: 46px;
          left: 14px;
          width: 25px;
          transform: rotate(45deg);
          animation: icon-line-tip 0.75s;
        }

        &.line-long {
          top: 38px;
          right: 8px;
          width: 47px;
          transform: rotate(-45deg);
          animation: icon-line-long 0.75s;
        }
      }

      .icon-circle {
        top: -4px;
        left: -4px;
        z-index: 10;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        position: absolute;
        box-sizing: content-box;
        border: 4px solid rgba(77, 175, 80, 0.3);
      }

      .icon-fix {
        top: 8px;
        width: 5px;
        left: 26px;
        z-index: 1;
        height: 85px;
        position: absolute;
        transform: rotate(-45deg);
        background-color: transparent;
      }
    }
  }

  @keyframes rotate-circle {
    0% {
      transform: rotate(-45deg);
    }
    5% {
      transform: rotate(-45deg);
    }
    12% {
      transform: rotate(-405deg);
    }
    100% {
      transform: rotate(-405deg);
    }
  }

  @keyframes icon-line-tip {
    0% {
      width: 0;
      left: 1px;
      top: 19px;
    }
    54% {
      width: 0;
      left: 1px;
      top: 19px;
    }
    70% {
      width: 50px;
      left: -8px;
      top: 37px;
    }
    84% {
      width: 17px;
      left: 21px;
      top: 48px;
    }
    100% {
      width: 25px;
      left: 14px;
      top: 45px;
    }
  }

  @keyframes icon-line-long {
    0% {
      width: 0;
      right: 46px;
      top: 54px;
    }
    65% {
      width: 0;
      right: 46px;
      top: 54px;
    }
    84% {
      width: 55px;
      right: 0px;
      top: 35px;
    }
    100% {
      width: 47px;
      right: 8px;
      top: 38px;
    }
  }
}
</style>